// main: master.scss
#contact {
    .row.master {
        max-width: 700px;
        margin: auto;
    }
    h3.page-subheading,
    .submit {
        text-align: center;
    }
    p#desc_contact0 {
        display: none;
    }
    .contact-form-box div.selector > span {
        border: none;
        padding: 0 0.7em !important;
    }
}
#contact section.container.page-content,
#cms section.container.page-content {
    margin-top: 220px;
}
div#opc_payment_methods {
    margin-bottom: 80px;
}
#HOOK_PAYMENT p.warning {
    width: 100%;
    margin-bottom: 40px;
}
.submit.opc-add-save button.btn.btn-default.button:nth-child(2) {
    background-color: transparent !important;
    color: $color-base !important;
}
.boxTerms {
    padding: 5px 10px;
}
#order-opc h3.page-heading {
    line-height: 54px !important;
}
#cms {
    .rte {
        width: 700px;
        margin: auto;
    }
}
#open-button {
    border: 1px solid $color-base;
    background-color: rgb(255, 255, 255) !important;
    padding: 0 !important;
    right: 0;
    left: unset;
    min-width: unset;
    height: 60px;
    margin: 17px 0;
    border-radius: 50px;
    -webkit-transition: margin 0.5s ease !important;
    transition: margin 0.5s ease !important;
    .fa {
        color: $color-base;
        line-height: 29px;
    }
    &:hover, &:focus {
        background-color: $color-base !important;
        -webkit-box-shadow: 0 0 20px rgba($color-base, 0.5);
        box-shadow: 0 0 20px rgba($color-base, 0.5);
        .fa {
            color: #fff;
        }
    }
}
#category {
    .breadcrumb-box .breadcrumb-inner {
        padding: 0 15px 10px 0;
        text-align: right;
    }
}
#product {
    .productFunc {
        margin-top: 20px;
    }
    #wallpaperCalc,
    .productFunc p {
        line-height: 28px;
        margin-bottom: 15px !important;
        font-size: 16px;
        #wallpaperCalcToogle,
        #wallpaperCalcToogle svg path,
        a svg path,
        a {
            fill: $color-base2;
            color: $color-base2;
            cursor: pointer;
            -webkit-transition: color 0.5s, fill 0.5s ease;
            transition: color 0.5s, fill 0.5s ease
        }
        &:hover {
            #wallpaperCalcToogle,
            #wallpaperCalcToogle svg path,
            a svg path,
            a {
                fill: $color-base;
                color: $color-base !important;
            }
        }
    }
    .productFunc svg {
        position: relative;
        top: 3px;
    }
}
.CTAsection {
    padding: 100px 0;
    margin-top: 100px;
    background-size: cover;
    .wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        width: 100%;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    h1 {
        font-size: 45px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        padding: 0;
    }
    p {
        font-family: $font2;
        font-size: 20px;
        color: rgb(255, 255, 255);
        font-weight: 500;
    }
}
section.page-product-box.blockproductscategory.accessories-section {
    margin: 0 50px 100px;
    .accessories-carousel,
    .productscategory-carousel {
        padding: 0 15px;
        margin: 0;
    }
    .accessories-carousel .owl-stage-outer,
    .productscategory-carousel .owl-stage-outer {
        padding-bottom: 0;
    }
    .product-box .product-info {
        padding-bottom: 0;
    }
    .product-box .product-info .product-cat,
    .product-box .product-info .product-cat {
        color: $color-alt5;
        text-align: left;
        font-size: 18px;
        margin-top: 10px;
    }
    .product-box .product-info a.product-name {
        font-size: 20px;
        text-align: left;
        font-weight: 500;
    }
    .product-box .product-info .content_price {
        margin-top: 0;
        text-align: right;
    }
    .product-box:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .owl-item .product-box .preview {
        height: auto !important;
    }
    .addon-title {
        margin-top: 100px;
    }
}
#wallpaperCalc {
    #wallpaperCalcFields {
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: top;
        transform-origin: top;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &.active {
            visibility: visible;
            opacity: 1;
            max-height: 500px;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
            margin-bottom: 40px;
        }
    }
    .inputs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .result {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    input {
        margin: 20px;
        max-width: 200px;
    }
    #result {
        margin: 0 0 0 20px !important
    }
    button {
        margin-left: 20px;
    }
}
.mailFormWrap {
    padding: 0 50px;
    margin: 50px 0;
}