// main: master.scss
/*1 all */ @media (min-width: 1px) { /*-------------------------------------------------------------------------------- all*/
@import "1-all-b";
@import "1-all-a";

#category .filterblock div.checker span div.checker span {
    display: none;
}
#enabled_filters {
    display: none;
}
table.paymentTable {
    width: 100%;
    margin-top: 20px;
    thead {
        background-color: $color-base;
        color: rgb(255, 255, 255);
    }
    thead td {
        border: 1px solid $color-base;
    }
    td {
        font-size: 16px;
        border: 1px solid rgb(173, 173, 173);
        width: 100%;
        white-space: nowrap;
    }
    td.text-right {
        text-align: right;
    }
    td.text-wrap {
        white-space: normal;
    }
    h3 {
        padding: 0;
        font-size: 13px;
    }
}
table.paymentTableSum {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    background-color: $color-base7;
    td {
        font-size: 16px;
        border: 1px solid #fff;
        color: #fff;
        white-space: nowrap;
    }
    td.text-right {
        text-align: right;
    }
}
.jms-row.category .layout-column.col-lg-3 {
    width: 25%;
}
.product-info a.product-btn.cart-button.ajax_add_to_cart_button.btn-effect {
    display: none !important;
}
.productscategory-carousel,
#product_list {
    .btn-effect:after {
        display: none;
    }
    a.product-btn.cart-button.ajax_add_to_cart_button.btn-effect {
        color: $color-base;
        background-color: $color-base2;
        &:hover, &:focus {
            color: $color-base2;
            background-color: $color-base;
        }
        &::before {
            color: $color-base;
        }
        &:hover::before, &:focus::before {
            color: $color-base2;
        }
    }
    .product-box .content_price.clearfix {
        text-align: right !important;
    }
    .product-box .product-cat {
        text-align: left !important;
        color: rgb(0, 0, 0);
        font-size: 16px;
        font-weight: 300;
    }
    .product-box .product-name {
        color: $color-base;
        text-align: left;
        font-size: 17px;
        font-weight: bold;
    }
    .product-box .product-info .cart-button {
        position: absolute !important;
        padding: 0 !important;
        width: 45px !important;
        right: 0 !important;
    }
    .product-box:hover {
        box-shadow: none;
    }
}
.breadcrumb-box {
    .breadcrumb-inner {
        padding: 0 0 10px 0;
        text-align: left;
        color: rgb(0, 0, 0);
        font-size: 14px;
    }
    .breadcrumb-inner .navigation-pipe:before {
        background-color: $color-alt5;
        content: "";
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: relative;
        top: -2px;
    }
    .row {
        margin: 0;
    }
    .breadcrumb-inner a {
        color: $color-base;
    }
}
.span.price.new,
.cart-wrap .description .price-quantity,
.product-box .product-info .content_price .price.new,
.our_price_display .price {
    color: $color-base;
    font-weight: bolder;
}
.owl-controls {
    cursor: pointer;
    .owl-prev,
    .owl-next {
        color: $color-alt3;
        &:hover, &:focus {
            color: $color-base;
        }
    }
    .owl-prev::after,
    .owl-next::after {
        font-family: FontAwesome;
        width: 100%;
        height: 100%;
        z-index: 1;
        font-size: 26px;
        line-height: 40px;
        padding: 0 10px;
    }
    .owl-next::after {
        content: "\f054";
    }
    .owl-prev::after {
        content: "\f053";
    }
}
.owl-theme .owl-controls .owl-nav div.owl-prev,
.owl-theme .owl-controls .owl-nav div.owl-next,
.product-box .cart-button {
    background-image: none;
    color: #fff;
    background-color: $color-base;
    border-radius: 50%;
    border: none;
    right: 0;
    bottom: 15px;
    opacity: 0;
    transition: all 0.5s ease;
    display: block !important;
    &:hover, &:focus {
        background-color: #fff;
        color: $color-base !important;
        border: none;
        }
    }
}
.product-box .preview .label {
    left: 0 !important;
    right: unset !important;
}
.product-box .preview .label.label-sale {
    top: 40px;
}
#product_list .item,
section.page-product-box.blockproductscategory.accessories-section .owl-item,
.productscategory-carousel,
.product-carousel-featured {
    .product-box .action-btn .box {
        border-top: none;
    }
    .product-box {
        margin-right: 50px !important;
        &:hover, &:focus {
            box-shadow: none;
            .cart-button {
                opacity: 1;
                right: 10px;
            }
        }
    }
    .product-box .preview {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }
    img.img-responsive.product-img1 {
        height: 200px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
        font-family: 'object-fit: cover; object-position: center;';
    }
    .owl-stage-outer {
        overflow: hidden !important;
        padding: 0 !important;
    }
    .product-box .product-info .product-cat {
        display: block;
    }
    .product-info-bottom {
        display: flex;
        flex-direction: row;
    }
    .product-info-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .product-info span.price.old {
        color: $color-alt3;
        text-decoration: line-through;
        font-size: 15px;
        font-weight: bold;
    }
    .product-info .content_price {
        text-align: right;
        white-space: nowrap;
        margin-top: 0;
    }
    .product-box .product-info a.product-name {
        text-align: left;
        color: #000;
        font-size: 17px;
        font-weight: bold;
        line-height: 28px;
    }
    .product-cat a {
        color: $color-alt3;
    }
    .product-box .product-info .content_price .price.old {
        margin-right: 10px;
        font-size: 15px;
    }
}
.catSlider {
    .btn-hover:hover {
        background: none !important;
        color: $color-alt3 !important;
    }
    a {
    &:hover, &:focus {
        text-decoration: underline;
        }
    }
    a.btn-show-all.btn-hover {
        position: absolute;
        bottom: 5px;
        right: 5px;
        padding: 0 5px;
        font-size: 1.2em;
    }
    ul.child-list {
        padding: 5px 10px;
        margin: 0 0 20px;
    }
    .owl-item {
        background-color: $color-alt2;
    }
    .owl-stage {
        display: flex;
    }
    li {
        display: inline-block;
        padding: 0 10px 0 0;
        font-size: 16px;
        &::after {
            content: "/";
            margin: 0 0 0 5px;
        }
        &:last-child::after {
            display: none;
        }
    }
    .categoy-image a {
        display: block;
        position: relative;
    }
    .categoy-image {
        img {
            filter: grayscale(100%) brightness(24%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
        }
        &:hover img, &:focus img {
            filter: grayscale(0%) brightness(44%) sepia(0%) hue-rotate(0deg) saturate(100%) contrast(0.8);
        }
    }
    .cat-name {
        font-size: 1.4em;
        font-weight: bold;
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(255, 255, 255);
        text-align: center;
    }
}
.content-aside.left {
    #enabled_filters {
        padding: 10px;
    }
    #layered_block_left #enabled_filters .layered_subtitle {
        float: left !important;
        width: 100%;
    }
    ul {
        margin-bottom: 0;
    }
    #layered_form div.checker,
    #layered_block_left li.nomargin > label,
    #layered_block_left .layered_filter_ul li a {
        display: inline;
    }
    #layered_form div.checker {
        margin-left: 15px;
    }
    .layered_filter ul li span {
        top: -0.3em;
    }
    #categories_block_left ul li ul {
        padding-left: 15px;
    }
    #categories_block_left li li li a {
        margin-left: 0;
    }
    #categories_block_left ul li a,
    #layered_block_left .layered_filter_ul li:last-child a,
    #categories_block_left .block_content>ul>li>a {
        color: $color-base;
        line-height: 20px;
        padding: 0 0 10px 0;
        font-size: 20px;
        font-weight: 400;
        padding-right: 30px;
        &:hover, &:focus {
            text-decoration: underline;
        }
        &.selected {
            font-weight: 500;
            color: $color-base2;
        }
    }
    #categories_block_left ul li ul li a {
        display: block;
        padding-bottom: 10px;
    }
    #categories_block_left li {
        padding-bottom: 10px;
    }
    #categories_block_left li ul li {
        padding-bottom: 0;
    }
    #layered_block_left .layered_filter_ul.layer-design,
    #layered_block_left .layered_filter,
    #layered_block_left .layered_price,
    #categories_block_left {
        background-color: $color-alt2;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 20px;
    }
    #layered_block_left .layered_slider_container {
        margin-bottom: 10px;
    }
    #layered_block_left #enabled_filters .layered_subtitle h3,
    #categories_block_left .title_block h3 {
        text-transform: unset;
        padding: 0;
    }
    #layered_block_left #enabled_filters .layered_subtitle,
    #categories_block_left .title_block {
        border-bottom: none;
        margin-bottom: 10px;
    }
    #layered_block_left #enabled_filters .layered_subtitle h3:before,
    #categories_block_left .title_block h3::before {
        display: none;
    }
    .ui-widget-content {
        width: calc(100% - 17px);
    }
    #categories_block_left li span.grower {
        right: 6px;
        top: 7px;
        line-height: 20px;
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 50%;
        padding-right: 1px;
        color: $color-base;
        background-color: $color-alt;
        &:hover, &:focus {
            color: $color-alt;
            background-color: $color-base;
        }
        &::before {
            color: $color-base;
        }
        &:hover::before, &:focus::before {
            color: $color-alt;
        }
    }
}
.pagination li > a,
.pagination li > span {
    background: rgba(0, 0, 0, 0) !important;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    color: $color-base;
    background: rgba(0, 0, 0, 0) !important;
}
.page-content {
    .product_list p {
        text-align: center;
        margin-bottom: 50px;
        max-width: unset;
    }
    .product-sort .select.selector1 {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .filters-panel {
        margin-top: 10px;
        border: none;
    }
    .content_scene_cat_bg .cat_desc h3 {
        color: $color-base;
        text-transform: uppercase;
        font-weight: bolder;
        font-family: $font1;
        font-size: 40px;
        padding: 0;
    }
    .content_scene_cat_bg .cat_desc .flex-box {
        max-width: unset;
    }
    .content_scene_cat_bg {
        display: block;
        text-align: left;
    }
    .content_scene_cat_bg .cat_desc {
        padding: 0 50px;
    }
}
section.page-content,
section.site-content {
    margin-top: 179px;
    margin-bottom: 20px;
    min-height: calc(100vh - 235px);
    .section-slider .row {
        margin: 0;
    }
    .section-slider .layout-column {
        padding: 0 !important;
    }
    p {
        font-size: 16px;
    }
    ul.footer_links.clearfix {
        text-align: center;
    }
    .page-heading {
        padding: 0;
        line-height: 28px;
    }
    .warning, .alert {
        font-size: 16px;
        max-width: unset;
    }
    .addon-title {
        position: relative;
        &::before {
            display: none;
        }
    }
    .blockproductscategory .addon-title h3,
    .addon-title h3 {
        font-family: $font1;
        font-size: 40px;
        color: #000;
        font-weight: bold;
        display: inline-block;
        padding: 0;
        text-transform: unset;
        margin-top: 0;
        &::before {
            display: none;
        }
    }
    .addon-title {
        margin: 40px 0px;
    }
    .content-aside.left #categories_block_left li span.grower {
        top: 0;
        background-color: $color-base;
        border-radius: 0;
        &:hover, &:focus {
            background-color: $color-base2;
        }
    }
    .content-aside.left #categories_block_left li span.grower::before {
        color: #fff;
    }
    #categories_block_left li span.grower.OPEN:before {
        content: "-";
        width: 100%;
        height: auto;
        top: 0;
        right: 0;
        background: $color-base2;
    }
    .home3 & {
        margin-bottom: 0;
    }
}
.container {
    width: 100%;
}
footer {
    color: rgb(255, 255, 255);
    .jms-row.footer-navbar {
        width: 100%;
        margin: auto;
        padding-top: 40px;
        background-color: #000;
    }
    img.logo.img-responsive {
        width: 200px;
    }
    #social_block {
        margin: 20px 0 0 0;
    }
    #social_block .fa {
        color: rgb(255, 255, 255);
    }
    section.blockcategories_footer.footer-block {
        margin: 0 0 20px 0;
    }
    .blockcategories_footer a,
    .footer-navbar p {
        font-size: 13px;
        margin-bottom: 0;
        white-space: nowrap;
    }
    .footer-navbar a {
        color:$color-alt5;
        font-size: 12px;
        padding-right: 10px;
        &:hover, &:focus {
            color: #fff !important;
            text-decoration: underline;
        }
    }
    .footer-navbar .layout-column:nth-child(1),
    .footer-navbar .layout-column:nth-child(2),
    .footer-navbar .layout-column:nth-child(3) {
        width: auto;
        padding: 0;
    }
    .footer-navbar .layout-column:nth-child(2) {
        min-width: 400px;
    }
    .footer-navbar .layout-column:nth-child(2) .addon-box {
        padding-left: 40px;
    }
    .footer-navbar h3 {
        font-weight: 500;
    }
    .footer-navbar span {
        white-space: normal;
    }
    .footer-navbar .row::before {
        display: none;
    }
    .footer-navbar .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        justify-content: space-between;
        &::after {
            display: none;
        }
    }
    iframe {
        border: none;
    }
    .category_footer ul ul {
        display: none !important;
    }
    .footer-navbar .container {
        padding: 70px 0 50px;
        width: 80%;
    }
    .footer-copyright {
        padding: 15px 0 5px;
        background-color: rgb(27, 27, 27);
    }
    .links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    #footer_thebricks_line {
        text-align: center;
        font-size: 11px;
        max-width: unset;
        line-height: 26px;
        color: rgb(218, 218, 218);
    }
    #footer_thebricks_link {
        text-decoration: none;
        font-family: "Myriad Pro", Myriad, sans-serif !important;
        font-weight: bold;
        margin-left: 3px;
        letter-spacing: 0.5px;
        white-space: nowrap;
        color: rgb(218, 218, 218);
        font-size: 12px;
    }
    #footer_thebricks_link:hover {
        color: $color-base !important;
    }
    #footer_thebricks_link:hover {
        padding: 4px 0px 1px 0px;
    }
    #footer_thebricks_THE {
        padding: 4px 3px 1px 0px;
        margin-left: -2px;
    }
    #footer_thebricks_BRICKS {
        border: 2px solid;
        padding: 3px 3px 1px 3px;
        margin-right: -2px;
    }
}
header {
    position: fixed;
    width: 100%;
    z-index: 8000;
    top: 0;
    box-shadow: 0 -10px 20px 10px rgba(0, 0, 0, 0.2);
    .mega-dropdown-inner .row .col-sm-3 {
        word-break: break-word;
        white-space: normal;
        padding-right: 40px;
        &:last-child {
            padding-right: 0;
        }
    }
    a.btn-xs.ic-search2 {
        text-decoration: none !important;
    }
    span.icon-basket {
        padding-right: 10px;
    }
    .jms-megamenu .dropdown-menu.fullwidth {
        width: 100%;
        left: 0;
        position: fixed;
        top: 159px;
        transition: unset;
        &.open {
            transition: all 0.3s ease;
        }
    }
    .header-toprow p a,
    .header-toprow p {
        padding: 0 20px 0 0 !important;
        margin: 0;
        font-size: 13px;
        font-weight: 500;
        color: $color-alt3;
        &:last-child {
            padding: 0 !important;
        }
    }
    .header-toprow p {
        text-transform: uppercase;
    }
    .header-toprow p a {
        text-transform: none;
    }
    .header-toprow .addon-box {
        display: flex;
        white-space: nowrap;
        padding: 10px 0;
    }
    .header-toprow .right,
    .header-toprow .left {
        width: auto;
        padding: 0;
        &::before, &::after {
            display: none;
        }
    }
    span.icon-magnifier {
        color: $color-alt5;
    }
    a.logo {
        max-width: 130px;
        display: block;
    }
    .search-box a.btn-xs.ic-search2 {
        position: absolute;
        left: -5px;
        line-height: 45px;
    }
    .header-toprow {
        border-bottom: 1px solid $color-border;
        background-color: #fff;
    }
    .header-toprow .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &::before, &::after {
            display: none;
        }
    }
    .header-top .right .addon-box:nth-child(3) {
        margin-right: 0;
    }
    .header-top .layout-column.left {
        width: auto;
        margin: 0;
        padding: 0;
    }
    .layout-column.menu {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 94px;
        align-items: center;
        white-space: nowrap;
        z-index: 1;
    }
    .jms-megamenu .nav > li > a {
        font-weight: bolder;
        height: 113px;
        align-items: center;
        display: flex;
        color: #000;
        width: 90px;
        white-space: normal;
        text-align: center;
        justify-content: center;
        font-size: 1.3em;
        line-height: 1.2em;
    }
    .jms-megamenu .nav > li.menu-item.vypredaj > a {
        color: #ef4036;
    }
    div#jms_ajax_search {
        margin-bottom: 6px;
        width: auto;
    }
    .checkout-info .label,
    .checkout-info .pull-right {
        color: rgb(0, 0, 0);
    }
    #cart_block .shoppingcart-box {
        max-height: 70vh;
    }
    .jms-megamenu .open > .dropdown-menu::after,
    #cart_block .shoppingcart-box::before {
        display: none;
    }
    .jms-megamenu .mega-nav li > a {
        color: #fff;
        border: none;
        font-weight: 400;
        padding: 0 0 0 5px;
        line-height: 20px;
        text-transform: none;
        font-size: 18px;
        &:hover, &:focus {
            color: #fff !important;
            text-decoration: underline;
            border: none;
        }
    }
    #cart_block a.cart-icon {
        padding: 12px 24px 12px 20px;
        min-width: 190px;
        text-align: center;
    }
    #cart_block .shoppingcart-box {
        background: rgba(0, 0, 0, 0);
        padding: 5px 0 0;
        z-index: 8001;
    }
    #cart_block .cart_block_list,
    #cart_block .checkout-info,
    #cart_block span.ajax_cart_no_product {
        background-color: #fff;
        padding: 10px 20px;
    }
    #cart_block span.ajax_cart_no_product {
        border: 2px solid $color-base;
        border-bottom: none;
    }
    #cart_block ul {
        border: 2px solid $color-base;
        border-bottom: none;
    }
    #cart_block .checkout-info {
        border: 2px solid $color-base;
        border-top: none;
    }
    #cart_block .ajax_cart_quantity {
        position: relative;
        top: -5px;
        left: 5px;
        padding: 1px;
        background-color: $color-base;
        height: 25px;
        width: 25px;
        line-height: 24px;
    }
    #jms_ajax_search #ajax_search:focus {
        outline: none;
    }
    #jms_ajax_search #search_result {
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        left: 20px;
        width: calc(100% - 40px);
        margin: 2px 0 0;
    }
    a.btn-xs {
        font-size: 16px;
    }
    .navbar-fixed-top {
        top: 0;
        position: relative;
        top: auto;
        -webkit-animation-name: unset !important;
        animation-name: unset !important;
    }
    .header-bottom {
        background-color: $color-alt !important;
        box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2) !important;
    }
    .header-top .right .addon-box:nth-child(2) {
        margin: 0;
        height: 60px;
        border: 1px solid $color-base;
        border-radius: 30px;
        &:hover, &:focus {
            background-color: $color-base;
            box-shadow: 0 0 20px rgba($color-base, 0.5);
            #cart_block .icon-basket,
            a.dropdown-toggle.cart-icon span {
                color: #fff;
            }
            #cart_block .ajax_cart_quantity {
                background-color: #fff;
                color: $color-base;
            }
        }
    }
    .header-top .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .mega-dropdown-inner .row {
        align-items: flex-start;
        width: 60%;
        margin: auto;
    }
    .jms-megamenu .nav > li {
        padding: 0 15px;
    }
    .jms-megamenu .nav > li.menu-item.mega:nth-child(1) {
        & a:hover {
            color: $color-base5 !important;
        }
        .mega-nav,
        .dropdown-menu {
            background-color: $color-base5 !important;
        }
        .mega-nav a,
        .dropdown-menu a {
            &:hover {
                color: #fff !important;
            }
        }
        &.open > a::after {
            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color-base5;
        }
    }
    .jms-megamenu .nav > li.menu-item.mega:nth-child(2) {
        & a:hover {
            color: $color-base6 !important;
        }
        .mega-nav,
        .dropdown-menu {
            background-color: $color-base6 !important;
        }
        .mega-nav a,
        .dropdown-menu a {
            &:hover {
                color: #fff !important;
            }
        }
        &.open > a::after {
            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color-base6;
        }
    }
    .jms-megamenu .nav > li.menu-item.mega:nth-child(3) {
        & a:hover {
            color: $color-base2 !important;
        }
        .mega-nav,
        .dropdown-menu {
            background-color: $color-base2 !important;
        }
        .mega-nav a,
        .dropdown-menu a {
            &:hover {
                color: #fff !important;
            }
        }
        &.open > a::after {
            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color-base2;
        }
    }
    .jms-megamenu .nav > li.menu-item.mega:nth-child(4) {
        & a:hover {
            color: $color-base7 !important;
        }
        .mega-nav,
        .dropdown-menu {
            background-color: $color-base7 !important;
        }
        .mega-nav a,
        .dropdown-menu a {
            &:hover {
                color: #fff !important;
            }
        }
        &.open > a::after {
            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color-base7;
        }
    }
    .jms-megamenu .nav > li.menu-item.mega:nth-child(n+5) {
        & a:hover {
            color: $color-base !important;
        }
        .mega-nav,
        .dropdown-menu {
            background-color: $color-base !important;
        }
        .mega-nav a,
        .dropdown-menu a {
            &:hover {
                color: #fff !important;
            }
        }
        &.open > a::after {
            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color-base;
        }
    }
    #cart_block .shoppingcart-box::after {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color-base;
    }
    li.menu-item.label {
        text-align: left;
        display: block;
        padding: 0;
    }
    li.menu-item.label a {
        pointer-events: none;
        font-size: 17px;
        padding-bottom: 15px;
        padding-left: 0;
        text-transform: uppercase;
        font-weight: bold;
    }
    li.menu-item.link a {
        font-size: 17px;
        padding-bottom: 15px;
        padding-left: 0;
        text-transform: uppercase;
        font-weight: bold;
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
    .jms-megamenu .mega-nav, .jms-megamenu .dropdown-menu .mega-nav {
        padding: 30px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .nav {
        text-align: left;
    }
    .header-top {
        padding: 10px 0;
        background-color: $color-alt2;
    }
    .header-top .right .addon-box:nth-child(3):after {
        display: none;
    }
    .header-top .right {
        width: auto;
        position: absolute;
        right: 50px;
        flex-direction: row;
        align-items: center;
        z-index: 2;
        padding: 0;
    }
    #jms_ajax_search #ajax_search {
        background: none;
        font-weight: 700;
        text-transform: uppercase;
        color: $color-alt5 !important;
    }
    .header-top .right #jms_ajax_search .ic-search2 {
        right: unset;
        padding: 0;
    }
    .header-top .right .addon-box:first-child {
        width: auto;
    }
    #cart_block .icon-basket,
    a.dropdown-toggle.cart-icon span {
        color: $color-base;
    }
    #cart_block .cart_block_total {
        display: inline !important;
    }
    .header-top .right .user-info ul li a {
        color: $color-base;
        &:hover, &:focus {
            color: $color-base2 !important;
        }
    }
}
input::-webkit-input-placeholder {
    color: $color-alt5 !important;
}
input:-moz-placeholder {
    color: $color-alt5 !important;
}
input::-moz-placeholder {
    color: $color-alt5 !important;
}
input:-ms-input-placeholder {
    color: $color-alt5 !important;
}
.form-ok::after {
    content: "a";
    content: "\f00c";
    float: right;
    margin-top: -30px;
    margin-right: 10px;
    font-family: "FontAwesome";
    color: rgb(0, 128, 0);
}
.form-error::after {
    content: "a";
    content: "\f00d";
    float: right;
    margin-top: -30px;
    margin-right: 10px;
    font-family: "FontAwesome";
    color: red;
}
.row {
    margin: 0 50px;
}
.product-box .product-info a.product-name:hover,
a {
    color: $color-base;
    &:hover, &:focus {
        color: $color-base2 !important;
    }
}
.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
    border: 1px solid $color-border !important;
}
.myaccount-link-list li a,
.btn-default,
.checkout-info .cart-button > a,
.btn,
button,
input[type="submit"] {
    cursor: pointer;
    min-width: 200px;
    height: auto;
    text-align: center;
    padding: 20px !important;
    background-image: none !important;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    border-radius: 10px;
    color: #fff !important;
    background-color: $color-base !important;
    &:hover, &:focus {
        color: $color-base !important;
        background-color: #fff !important;
    }
    &::before, &::after {
        display: none;
    }
}
textarea.form-control {
    border: 1px solid $color-base;
    &:hover, &:focus {
        border: 1px solid $color-base2;
    }
    &:focus {
        outline: 1px solid rgba(0, 0, 0, 0.2);
    }
}
#contact .contact-form-box div.selector > span,
#address form div#uniform-id_country span,
#order-opc form div#uniform-id_country_invoice span,
#order-opc form div#uniform-id_country span,
.woocommerce .quantity input.qty,
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select {
    color: $color-text !important;
    background-color: transparent;
    padding: 0.7em;
    font-size: 16px;
    width: 100%;
    border-color: transparent;
    border-bottom: 1px solid $color-base;
    &:hover, &:focus {
        border-color: transparent;
        border-bottom: 1px solid $color-base2;
        background-color: transparent;
    }
    &:focus {
        outline: 1px solid rgba(0, 0, 0, 0.2);
    }
}
#order-opc form div#uniform-id_country_invoice,
#order-opc form div#uniform-id_country {
    &:hover span, &:focus span {
        border-color: transparent;
        border-bottom: 1px solid $color-base;
        background-color: transparent;
    }
    &:focus span {
        outline: 1px solid rgba(0, 0, 0, 0.2);
    }
}
/*scrollbar*/
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    ::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border: 0px none #ffffff;
        border-radius: 0;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #bfbfbf;
    }
    ::-webkit-scrollbar-thumb:active {
        background: $color-base;
    }
    ::-webkit-scrollbar-track {
        background: #666666;
        border: 0px none #ffffff;
        border-radius: 0px;
    }
    ::-webkit-scrollbar-track:hover {
        background: #666666;
    }
    ::-webkit-scrollbar-track:active {
        background: #333333;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }/* end.all*/

/*
    color: lighten( $color-base, 20%); - vygeneruje odvodenú farbu
    width: count(x); - vypočíta šírku bloku podľa celkového počtu horizontálnych blokov
    @include font-size(x); - veľkosť písma v relatívnych jednotkách em
    @include flex(x); - display: flex + row/column
*/