// main: master.scss
/*animation ---------------------------------------------------------------------------------------------------- animation */
.info2 .btn-exclusive::after {
    transition: left 0.5s ease;
    -moz-transition: left 0.5s ease;
    -ms-transition: left 0.5s ease;
    -webkit-transition: left 0.5s ease;
    -o-transition: left 0.5s ease;
}
#scrollToTop {
    transition: padding 0.5s, opacity 0.5s ease;
    -moz-transition: padding 0.5s, opacity 0.5s ease;
    -ms-transition: padding 0.5s, opacity 0.5s ease;
    -webkit-transition: padding 0.5s, opacity 0.5s ease;
    -o-transition: padding 0.5s, opacity 0.5s ease;
}
header .layout-column.menu {
    transition: height 0.5s ease;
    -moz-transition: height 0.5s ease;
    -ms-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
}
.section-slider .btn a::after,
#product img#bigpic,
#open-button,
.section-slider .btn-shadow,
.section-slider .fraction-slider .fs_obj.btn,
.owl-controls .owl-prev, .owl-controls .owl-next,
#index .category .jms-banner,
.header-top .right .addon-box:nth-child(2),
.productscategory-carousel .owl-item:hover img.img-responsive.product-img1,
#product_list .product-box img,
td.cart_delete.text-center i,
td.delivery_option_radio,
.btn-default,
.quantity-box #add_to_cart,
#categories_block_left li span.grower,
.productSlider .owl-item img.img-responsive.product-img1,
.owl-theme .owl-controls .owl-nav div.owl-prev,
.owl-theme .owl-controls .owl-nav div.owl-next,
.jms-megamenu .nav > li,
a, a:visited, a:focus {
    transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, -webkit-transform 0.5s ease;
    transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s ease;
    transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, transform 0.5s ease;
    transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, transform 0.5s ease, -webkit-box-shadow 0.5s, -webkit-transform 0.5s ease;
    transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, transform 0.5s ease, -webkit-transform 0.5s ease;
    -webkit-transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, -webkit-transform 0.5s ease;
    -webkit-transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, -webkit-transform 0.5s ease;
    -webkit-transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s ease;
    -webkit-transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, transform 0.5s ease;
    -moz-transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, transform 0.5s ease;
    -ms-transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, transform 0.5s ease;
    -o-transition: border-color 0.5s, color 0.5s, background 0.5s, background-color 0.5s, box-shadow 0.5s, transform 0.5s ease;
}
/*
.class {
    animation-name: fadein;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
/* end.animation*/
