// main: master.scss
/*special */ @media (min-width: 960px) and (max-width: 1239px) { /*------------------------------------------------ special*/
.special {
}
}/* end.special*/
/*special */ @media (min-width: 992px) and (max-width: 1350px) { /*------------------------------------------------ special*/
.jms-megamenu .nav > li > a {
    font-size: 13px;
    line-height: 14px;
}
header .jms-megamenu .nav>li {
    padding: 11px 5px !important;
}
header a.logo {
    max-width: 90px;
}
.header-top .right .user-info {
    padding-right: 0;
}
header .header-top .right {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}
.header-top .right .user-info ul li a {
    padding: 0;
}
}/* end.special*/
/*special */ @media (min-width: 992px) and (max-width: 1200px) { /*------------------------------------------------ special*/
header .jms-megamenu .nav>li>a {
    height: auto;
}
header .layout-column.menu {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    align-items: flex-start;
}
}/* end.special*/
/*special */ @media (max-width: 1340px) { /*------------------------------------------------ special*/
#product form#buy_block {
    display: block;
}
#product .content_prices {
    margin-bottom: 20px;
}
#product .quantity-box .option {
    margin-right: 20px;
}
#product .quantity-box #add_to_cart {
    padding: 5px 0 !important;
}
.info2 .layout-column {
    padding: 0 20px;
    width: calc((100% / 4) - 19px);
}
header #cart_block a.cart-icon {
    min-width: unset;
    padding: 12px 14px 12px 15px;
}
header .jms-megamenu .nav>li>a {
    max-width: 90px;
    white-space: normal;
    text-align: center;
}
}/* end.special*/
/*special */ @media (max-width: 1440px) { /*------------------------------------------------ special*/
header #cart_block a.cart-icon {
    min-width: unset;
    padding: 12px 14px 12px 15px;
}
header .jms-megamenu .nav>li>a {
    max-width: 90px;
    white-space: normal;
    text-align: center;
}
}/* end.special*/
/*special */ @media (max-width: 1460px) { /*------------------------------------------------ special*/
header .header-toprow .right {
    display: none;
}
}/* end.special*/
/*special */ @media (min-width: 768px) and (max-width: 1130px) { /*------------------------------------------------ special*/
header .jms-megamenu .nav>li {
    padding: 11px 15px;
}
header .header-top .menu .addon-box:nth-child(2) {
    width: calc(100% - 630px);
}
.info2 .layout-column {
    padding: 0 10px;
    width: calc((100% / 4) - 19px);
}
}/* end.special*/
/*special */ @media (min-width: 768px) and (max-width: 991px) { /*------------------------------------------------ special*/
.info tr {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
}
.info .layout-column.col-lg-4 {
    width: count(3);
}
header .header-top .menu .addon-box:nth-child(2) {
    width: calc(100% - 320px);
}
.row {
    margin: 0 20px;
}
header .header-top .right {
    right: 75px;
}
}/* end.special*/
/*special */ @media (max-width: 991px) { /*------------------------------------------------ special*/
header div#jms_ajax_search {
    display: none;
}
input#ajax_search {
    background: rgb(224, 224, 224) !important;
}
#order-opc .customerAuth {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-align-items: center;
    align-items: center;
}
form#login_form {
    margin-bottom: 10px;
}
div#opc_account_choice {
    text-align: center;
    margin: 0 !important;
}
#order-opc #openLoginFormBlock {
    margin-left: 0;
}
}/* end.special*/
/*special */ @media (max-width: 410px) { /*------------------------------------------------ special*/
header #cart_block a.cart-icon {
    min-width: auto;
}
header span.icon-basket {
    padding-right: 0;
}
}/* end.special*/
/*special */ @media (min-width: 2226px) { /*------------------------------------------------ special*/
#index .category .layout-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}
}/* end.special*/
@media (pointer: coarse) { /*---------------------------------------------------------------------------------------- touch*/
    // header .jms-megamenu .nav>li>a {
    //     pointer-events: none;
    // }
}/* end.touch*/
@media (pointer: fine) { /*------------------------------------------------------------------------------------------ mouse*/
}/* end.mouse*/
