// main: master.scss
/*colors ---------------------------------------------------------------------------------------------------------- colors */;
$color-text: #000;
$color-base: #EF4036;
$color-base2: #92278F;
$color-base5: #F7941D;
$color-base6: #8CC63F;
$color-base7: #29ABE1;
$color-alt: rgb(255, 156, 0);
$color-alt2: rgb(255, 255, 255);
$color-alt3: rgb(152, 152, 152);
$color-alt4: rgb(239, 239, 239);
$color-alt5: rgb(171, 171, 171);
$color-border: rgb(171, 171, 171);

$color-selection-text: rgba(255, 255, 255, 0.8);
$color-selection-bg: rgba(0, 0, 0, 0.5);
$color-focus: rgba(0, 0, 0, 0.5);

@include selection{background-color:$color-selection-bg;color:$color-selection-text}
@if ($focusInput) {
    @include focusInput{outline: 1px solid $color-focus}
}
/* end.colors */
