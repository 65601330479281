// main: master.scss
/*fonts ------------------------------------------------------------------------------------------------------------ fonts */;
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

$font1: 'Nunito', sans-serif;
$font2: 'Roboto', sans-serif;

header #jms_ajax_search #ajax_search,
p, a, label, body {
    font-family: $font2;
    font-size: 16px;
}
h1, h2, h3, h4 {
    font-family: $font1;
}
body {
    font-size: 100%;
}
/* end.fonts */
