// main: master.scss
/*3 tablets */ @media (max-width: 1024px) { /*--------------------------------------------------------------------- tablets*/
header {
    .jms-megamenu .nav>li {
        padding: 11px 10px;
    }
    .header-toprow .row {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .header-toprow p a,
    .header-toprow p {
        font-size: 12px;
    }
}
#product {
    .productParametersWrap .item {
        width: 100%;
    }
    .heading .col2 p {
        width: 120px;
    }
}
.info2 strong {
    font-size: 15px;
}
.info2 p {
    font-size: 13px;
}
.info2 h5 {
    line-height: 18px;
    word-break: break-word;
}
.info2 .layout-column:last-child {
    min-width: 200px;
}
footer {
    .links {
        display: block;
    }
    .footer-navbar .layout-column:nth-child(2) {
        min-width: 200px;
    }
}
}/* end.tablets*/
