// main: master.scss
/*5 smartphones - portrait */ @media (max-width: 480px) { /*-------------------------------------- smartphones - portrait*/
#product_list .item,
.productscategory-carousel {
    .product-box {
        margin-right: 0 !important;
    }
}
#identity {
    .box {
        max-width: 700px;
        margin: auto;
    }
    .birth .row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .birth .row div {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
#addresses,
#address {
    button#submitAddress {
        margin-top: 10px;
    }
}
ul.footer_links li {
    margin-right: 0;
    margin-bottom: 10px;
}
#my-account {
    .myaccount-link-list li {
        width: 100%;
    }
    ul.footer_links li {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
.myaccount-link-list li a,
.btn-default,
.checkout-info .cart-button > a,
.btn,
button,
input[type="submit"] {
    min-width: 150px;
}
#guest-tracking,
#order-confirmation,
#password,
#module-cashonpickup-validation,
#module-cashondeliveryplus-validation,
#module-cheque-payment,
#module-bankwire-payment {
    .footer_links,
    .cart_navigation {
        display: block;
        text-align: center;
    }
}
#address,
#authentication,
#order-opc {
    .auth-left, .auth-right {
        padding: 0;
    }
    .orderNote {
        display: block;
    }
    .customerAuth {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    td.delivery_option_price {
        width: 100px;
    }
}
#product {
    .heading {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 30px;
    }
    .heading .col2 p {
        text-align: center;
    }
    .heading .col2 {
        margin-top: 20px;
    }
    h1 {
        text-align: center;
    }
    .pb-right-column {
        margin-top: 0;
        padding: 0;
    }
    .content_prices {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .product-description .quantity-box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    .quantity-box .option {
        margin-right: 0 !important;
        margin-bottom: 20px;
    }
    .quantity-box #add_to_cart {
        float: unset;
    }
    .primary_block.row {
        margin: 0;
    }
    .quantity-box #add_to_cart {
        width: 100%;
    }
}
.content_scene_cat_bg .cat_desc {
    padding: 0;
}
.breadcrumb-box {
    padding: 0;
}
footer {
    margin-top: 50px;
}
.breadcrumb-box .breadcrumb-inner {
    padding: 0;
}
.product_list .item.ajax_block_product {
    width: 100%;
}
.productscategory-carousel .product-box img, #product_list .product-box img {
    -webkit-transform: scale(1);
    transform: scale(1);
}
#categories_block_left li {
    position: relative;
    display: inline-block;
    padding-right: 30px;
}
#category {
    .content-aside.left #categories_block_left ul li a,
    .content-aside.left #layered_block_left .layered_filter_ul li:last-child a,
    .content-aside.left #categories_block_left .block_content>ul>li>a {
        font-size: 15px;
        padding-right: 0;
    }
    .row.master {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        margin: 0;
    }
    section#center_column {
        width: 100%;
        float: unset;
    }
    aside.content-aside.left {
        border-right: none;
        border-top: 1px solid $color-alt5;
        width: 100%;
        float: unset;
    }
    .filterblock .btn {
        width: 100%;
    }
}
.productscategory-carousel .owl-stage, .product-carousel-featured .owl-stage {
    margin-left: 10px;
}
.pb-left-column #image-block {
    float: unset;
    width: 100% !important;
    padding-left: 0;
}
.productscategory-carousel .owl-item img.img-responsive.product-img1, .product-carousel-featured .owl-item img.img-responsive.product-img1 {
    -webkit-transform: scale(1);
    transform: scale(1);
}
.productscategory-carousel .product-box, .product-carousel-featured .product-box {
    margin-right: 20px !important;
}
header #cart_block .ajax_cart_quantity {
    left: 0;
}
header #cart_block a.cart-icon {
    padding: 7px 8px 6px 10px;
}
header a.logo {
    max-width: 180px;
}
header .header-top .right .addon-box:first-child {
    display: none;
}
.owl-controls .owl-prev::after, .owl-controls .owl-next::after {
    font-size: 16px;
}
section.page-content,
section.site-content {
    .addon-title h3,
    .blockproductscategory .addon-title h3 {
        font-size: 19px;
        margin-bottom: 0;
        padding: 20px 0;
    }
}
#cart_block .shoppingcart-box {
    width: 250px;
}
footer #footer_thebricks_line {
    line-height: 27px;
    width: 70%;
    margin: auto;
}
.info {
    .row {
        display: block;
    }
    .layout-column.col-lg-4 {
        width: 100%;
    }
}
.info2 .layout-column:last-child {
    position: relative;
    width: 100% !important;
    padding: 20px 50px !important;
}
.info2 .layout-column {
    width: 100% !important;
    text-align: center;
}
#social_block ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
#index .category {
    .row {
        margin: 0;
    }
    .layout-column {
        width: 100%;
        margin-bottom: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;
        padding: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    h2 {
        text-align: center;
        padding: 0;
    }
    p {
        margin: 0;
        text-align: center;
    }
    .jms-banner {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .jms-banner img {
        width: 100%;
    }
}
.productSlider {
    margin-top: 0;
}
.section-slider {
    .btn a {
        font-size: 13px !important;
    }
    .fraction-slider .fs_obj.text2 {
        font-size: 35px !important;
        line-height: 35px !important;
    }
    .fraction-slider {
        height: 300px !important;
    }
}
header #cart_block .cart_block_total {
    display: none !important;
}
.brands {
    .owl-stage {
        width: 100% !important;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-transform: none !important;
        transform: none !important;
    }
    .owl-item {
        margin-right: 0 !important;
        width: count(3) !important;
    }
    .owl-item.cloned {
        display: none;
    }
    img {
        width: 60% !important;
    }
}
}/* end.smartphones - portrait*/
