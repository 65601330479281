// main: master.scss
#manufacturer {
    ul#manufacturers_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    #manufacturers_list .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 250px;
        margin-bottom: 50px;
    }
    .mansup-container .row {
        text-align: center;
    }
}

#category {
    .row.master {
        display: flex;
        flex-direction: row;
    }
    aside.content-aside.left {
        border-right: 1px solid $color-alt5;
        width: 25%;
        float: left;
    }
    section#center_column {
        width: 75%;
        float: left;
    }
    span.navigation-page {
        font-weight: 500;
    }
    span.navigation-page a {
        text-decoration: underline;
        color: $color-base;
    }
    section#center_column #layered_form div.checker,
    section#center_column #layered_block_left li.nomargin > label,
    section#center_column #layered_block_left .layered_filter_ul li a {
        display: inline;
    }
    .filterblock.row.clearfix {
        margin-top: 30px;
    }
    .filterblock .btn {
        min-width: auto;
        padding: 5px 15px !important;
        margin-top: 2px;
        border-radius: 0;
        &:hover,
        &.active {
            color: #fff !important;
            background-color: $color-base2 !important;
        }
    }
    .filterblock #layered_block_left .layered_filter_ul li {
        display: inline-block;
        margin-right: 20px;
    }
    .filterblock #layered_block_left .layered_filter_ul li a {
        border-bottom: none;
    }
    .filterblock .layered_filter_ul {
        border: 1px solid $color-alt5;
        padding: 15px;
        margin-top: -1px;
    }
    .filterblock div.checker span {
        top: 3px;
    }
    .filterblock .ui-slider-horizontal .ui-slider-range {
        background: $color-base2;
    }
    .filterblock .layered_slider_container {
        max-width: 300px;
        margin-bottom: 10px;
    }
    .filterblock span#layered_price_range {
        font-size: 16px;
    }
}
.menu-wrap {
    overflow: auto;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.2) !important;
    padding: 0 15px;
    #jms_ajax_search {
        margin: 10px 0 0;
    }
    #jms_ajax_search #ajax_search {
        &:hover, &:focus {
            outline: none !important;
        }
    }
    p#header_user_info {
        width: auto;
        display: inline;
        float: right;
    }
    p#header_user_info a {
        color: #fff;
        padding-right: 30px;
        &:hover, &:focus {
            color: #fff !important;
            text-decoration: underline;
        }
    }
    div#off-canvas-title {
        background-color: $color-base;
        padding: 0px 15px;
    }
    .close-button {
        min-width: unset;
        right: 0px;
        top: 12px;
        background-color: rgba(0, 0, 0, 0) !important;
    }
    #off-canvas-menu ul.nav > li > a {
        font-size: 17px;
        font-weight: bolder;
    }
    button#close-button{
        &::after {
            content: "\f00d";
            display: block;
            font: normal normal normal 14px/1 FontAwesome;
            height: 25px;
            position: absolute;
            left: 7px;
            top: 0;
            line-height: 25px;
        }
        &:hover {
            color: rgb(0, 0, 0) !important;
        }
    }
    .label {
        display: block;
        padding: 20px 0 0;
        border-radius: 0;
    }
    .label a {
        padding: 0 !important;
        font-weight: 600 !important;
    }
}
.section-slider {
    margin-top: -20px;
    .fraction-slider .prev, .fraction-slider .next {
        background: rgba(0, 0, 0, 0);
        color: $color-base;
        &:hover {
            color: #fff !important;
        }
    }
    .jms-slider-wrapper .slide {
        overflow: visible;
    }
    .fraction-slider .fs_obj.text1 {
        left: 10%;
        top: 25% !important;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 25px !important;
        text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
        color: rgba(255,255,255,.7) !important;
    }
    .fraction-slider .fs_obj.text2 {
        font-family: $font1;
        left: 10%;
        top: 41% !important;
        text-transform: uppercase;
        font-size: 57px !important;
        line-height: 60px !important;
        font-weight: 600;
        text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    }
    .fraction-slider .fs_obj.btn {
        height: 66px !important;
        left: 10%;
        top: unset !important;
        bottom: 30px !important;
        background-color: $color-base !important;
        padding: 15px 25px !important;
        border-radius: 10px;
        box-shadow: 5px 5px 5px rgba($color-base, 0.3);
        &:hover, &:focus {
            background-color: #fff !important;
            box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
            .btn-shadow {
                border: 2px solid $color-base2;
            }
            a {
                color: $color-base !important;
                &::after {
                    color: $color-base;
                }
            }
        }
    }
    .btn-shadow {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute !important;
        left: -10px !important;
        top: 10px !important;
        border: 2px solid $color-base2;
    }
    .btn a {
        color: rgb(255, 255, 255);
        left: -20px;
        &:hover {
            color: rgb(255, 255, 255) !important;
        }
        &::after {
            content: ">";
            color: rgb(132, 35, 30);
            position: absolute;
            right: -40px;
            font-size: 15px;
        }
    }
    .fs-pager-wrapper {
        left: unset;
        bottom: 50px;
        right: 60px;
        text-align: right;
    }
    .fs-pager-wrapper a {
        margin-right: 10px;
    }
    .fs-pager-wrapper .active,
    .fs-custom-pager-wrapper .active {
        width: 9px;
        background-color: #fff;
    }
}
#social_block ul li a {
    color: $color-base2 !important;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 0;
}
.info2 {
    background-color: $color-alt4;
    .btn-exclusive {
        background-color: $color-base7 !important;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 0;
        bottom: 0;
        color: rgba(0, 0, 0, 0);
        &::after {
            content: "\f054";
            font-family: "FontAwesome";
            font-size: 25px;
            line-height: 56px;
            position: absolute;
            left: 17px;
            color: rgb(255, 255, 255);
        }
    }
    a.link {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: rgba(0, 0, 0, 0) !important;
        z-index: 1;
    }
    p {
        margin: 0;
    }
    .layout-column {
        width: auto;
        padding: 0 50px;
    }
    .layout-column a {
        color: #fff;
    }
    h5 {
        font-size: 15px;
        line-height: 25px;
        text-transform: uppercase;
        padding: 10px;
    }
    .row {
        width: 80%;
        margin: auto;
        height: 140px;
        background-color: $color-base;
        color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: -70px;
    }
    .layout-column:last-child {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        right: 0 !important;
        position: absolute;
        background-color: #3b5998;
        min-width: 280px;
        &:hover, &:focus {
            .btn-exclusive {
                &::after {
                    left: 25px;
                }
            }
        }
    }
}
.info {
    background-color: $color-alt4;
    .row {
        display: flex;
        flex-direction: row;
        width: 80%;
        margin: auto;
        padding: 70px 10px;
        color: rgb(128, 128, 128);
        font-size: 18px;
    }
    img {
        opacity: 0.5;
        min-width: 48px;
    }
    td {
        vertical-align: middle;
        &:first-child {
            width: 70px;
            vertical-align: top;
        }
    }
    .addon-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
.productSlider {
    margin-top: 50px;
    .owl-controls {
        display: inline-block;
    }
    .owl-nav {
        display: flex;
        flex-direction: row;
    }
}
#index .category {
    margin-top: 50px;
    .jms-banner .img-banner:before, .jms-banner .img-banner:after {
        display: none;
    }
    .layout-column.col-lg-3 {
        .addon-box:nth-child(2) a {
            opacity: 0;
            visibility: hidden;
            margin-left: 50%;
            transition: opacity 0.5s, visibility 0.5s, margin 1s ease;
        }
        &:hover .addon-box:nth-child(2) a {
            opacity: 1;
            visibility: visible;
            margin-left: 0;
        }
    }
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .jms-banner {
        margin-bottom: 15px;
    }
    p {
        font-family: $font1;
        font-weight: 700;
        font-size: 12px;
        color: $color-alt5;
        text-align: left;
    }
    h2 {
        font-family: $font1;
        font-weight: 700;
        font-size: 30px;
        color: #000;
        padding: 0 0 15px;
    }
    p a {
        padding-bottom: 10px;
        color: $color-base;
    }
    .layout-column {
        width: 20%;
        &:last-child {
            display: none;
        }
    }
}
.brands {
    margin-top: 50px;
    .owl-stage {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    p {
        text-align: right;
        margin: 0;
        text-transform: uppercase;
        font-weight: bold;
    }
    img {
        height: 100px;
        width: auto !important;
        margin: auto;
    }
    .owl-item {
        -webkit-filter: grayscale(100%) brightness(20%);
        filter: grayscale(100%) brightness(20%);
        opacity: 0.8;
    }
    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
    }
}
#my-account {
    .myaccount-link-list li {
        width: 50%;
        float: unset;
        margin: 10px auto;
        padding: 0;
        max-width: 415px;
    }
    .page-heading {
        border-bottom: none;
        text-align: center;
    }
    .addresses-lists p {
        text-align: center;
        margin: auto;
    }
}
.jms-slider-wrapper {
    .fs-pager-wrapper a, .fs-custom-pager-wrapper a {
        background-color: $color-base;
    }
    .fs_loader {
        height: 500px;
    }
}

#scrollToTop {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: $color-base;
    opacity: 1 !important;
    z-index: 999;
    &:hover, &:focus {
        opacity: 0.9 !important;
        padding-bottom: 10px;
    }
    &:after {
        content: "\f077";
        color: rgb(255, 255, 255);
        font-family: FontAwesome;
        font-size: 14px;
    }
}
#cookieNotice {
    box-shadow: none !important;
    .closeFontAwesome {
        &:hover:before, &:focus:before {
            color: $color-base2;
        }
    }
}

#identity {
    .box {
        max-width: 700px;
        margin: auto;
    }
    .birth .row {
        margin: 0;
        display: flex;
        flex-direction: row;
    }
    .birth .row div {
        margin-right: 20px;
    }
}
#addresses,
#address {
    .address.box li {
        font-size: 13px;
    }
    li.address_update {
        text-align: center;
    }
    .main-page-indent {
        text-align: center;
    }
    .page-subheading,
    .page-heading {
        border-bottom: none;
        text-align: center;
    }
    .box label,
    .box p,
    form p {
        font-size: 16px;
        line-height: 26px;
    }
    section.page-content,
    #center_column {
        width: 100%;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }
}
#authentication {
    .page-heading {
        border-bottom: none;
        text-align: center;
    }
    .box label,
    .box p,
    form p {
        font-size: 16px;
        line-height: 26px;
    }
    #noSlide {
        width: 100%;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }
}
#guest-tracking,
#order-confirmation,
#password,
#module-cashonpickup-validation,
#module-cashondeliveryplus-validation,
#module-cheque-payment,
#module-bankwire-payment {
    .page-heading {
        border-bottom: none;
        text-align: center;
    }
    p.submit {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .footer_links,
    .cart_navigation {
        margin-top: 15px;
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .cart_navigation button.exclusive_large,
    .cart_navigation input.exclusive_large {
        margin-bottom: 0 !important;
    }
    .box label,
    .box p,
    form p {
        font-size: 16px;
        line-height: 26px;
    }
    form a:hover {
        text-decoration: underline;
    }
    .exclusive_large {
        margin-left: 20px;
    }
    section.page-content,
    #center_column {
        width: 100%;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }
    section.page-content .row {
        background-color: $color-alt2;
        padding: 10px;
        border-radius: 10px;
        margin: 0;
    }
}
#address,
#authentication,
#order-opc {
    div#opc_account_choice {
        margin: 0 !important;
    }
    div#uniform-id_address_delivery {
        display: inline-block;
    }
    li.address_update {
        text-align: right;
    }
    .address.box li,
    .addresses p {
        font-size: 16px;
    }
    .boxTerms {
        margin: 60px 0 10px;
    }
    .boxTerms div.checker {
        position: absolute;
        top: 3px;
    }
    .boxTerms label {
        text-indent: 20px;
    }
    .orderNote {
        display: flex;
        flex-direction: row;
        margin: 20px 0 0;
    }
    .orderNote p {
        font-size: 16px;
        white-space: nowrap;
        padding: 0 10px 0 0;
    }
    .carrierTarget tr {
        td.delivery_option_radio {
            background-color: $color-base !important;
        }
        &:hover td.delivery_option_radio, &:focus td.delivery_option_radio {
            background-color: $color-base2 !important;
        }
    }
    .carrierTarget {
        width: 100%;
        margin-bottom: 0;
    }
    td.delivery_option_radio {
        text-align: center;
        width: 20px;
    }
    div.radio span {
        position: relative;
        margin-top: 8px;
    }
    td.delivery_option_logo {
        width: 100px;
        text-align: center;
    }
    td.delivery_option_price {
        width: 200px;
        text-align: center;
    }
    img.order_carrier_logo {
        width: 70px;
    }
    table.resume.table.table-bordered {
        margin-bottom: -1px;
    }
    h1#cart_title {
        padding-top: 0;
    }
    .cart_navigation {
        margin-top: 0;
        margin-bottom: 0;
    }
    .customerAuth {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    #openLoginFormBlock {
        margin-left: 15px;
    }
    #opc_account_form {
        font-size: 16px;
    }
    form#new_account_form {
        width: 50%;
    }
    #opc_delivery_methods,
    form #opc_account_form {
        width: 100%;
        max-width: 550px;
        margin: auto;
    }
    form label {
        margin-bottom: 0px;
    }
    form .date-select .col-xs-4 {
        width: 103px;
        margin: 5px 10px 0;
    }
    form h3.page-subheading.top-indent {
        margin-top: 50px;
        text-align: center;
    }
    form div#uniform-id_country_invoice,
    form div#uniform-id_country_invoice span,
    form div#uniform-id_country,
    form div#uniform-id_country span {
        width: 100% !important;
        line-height: 1em;
    }
    form button#submitGuestAccount {
        font-size: 1em;
        float: right;
    }
    form#login_form {
        font-size: 16px;
    }
    #new_account_form fieldset,
    #login_form fieldset {
        padding: 0;
    }
    div#HOOK_PAYMENT {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    form .address2,
    form .optin,
    form .newsletter,
    form .date-select,
    form .gender-line {
        display: none;
    }
}

#cart_summary {
    font-size: 16px;
    max-width: 1120px;
    margin: 0 auto 30px;
    .cart_product img {
        height: 60px;
        width: auto;
    }
    td.cart_delete.text-center i {
        font-size: 1.1em;
        &:hover, &:focus {
            color: red;
        }
    }
    tfoot td {
        vertical-align: middle;
    }
    ul {
        margin: 0;
    }
    .cart_discount_delete,
    .cart_discount_price,
    tfoot td.price,
    li.price,
    span.price {
        text-align: right;
        font-size: 15px;
        white-space: nowrap;
    }
    li.old-price {
        text-decoration: line-through;
    }
    thead {
        background-color: $color-alt2;
    }
    .quantity-box input.cart_quantity_input {
        border-bottom: none !important;
    }
    td.cart_product {
        text-align: center;
    }
    td.cart_total {
        text-align: right !important;
    }
    p.product-name {
        margin-bottom: 0;
        font-size: 1.4em;
        &.gift {
            font-size: 16px;
        }
    }
    td#total_price_container {
        font-weight: bold;
    }
    #cart_voucher button {
        float: right;
        margin-top: 20px;
        min-width: 70px;
        font-size: 1.1em;
    }
    .cart_quantity_button {
        padding-top: 0 !important;
    }
    tbody + tbody {
        border: none;
    }
    .cart_avail span.label {
        white-space: normal;
        max-width: 200px !important;
        display: inline-block;
        line-height: 17px;
        padding: 5px;
    }
}
sup {
    color: rgb(255, 0, 0);
    font-size: 1.5em;
    top: 1px;
}

#views_block {
    width: 100% !important;
    padding: 24px 0 !important;
    #thumbs_list ul#thumbs_list_frame {
        display: flex;
    }
    li {
        margin-bottom: 0 !important;
        width: count(4) !important;
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
    .bx-controls.bx-has-controls-direction {
        display: none;
    }
    .bx-viewport {
        height: auto !important;
    }
    #thumbs_list li a {
        border: none;
        box-shadow: none !important;
    }
}
#product {
    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
        justify-content: space-between;
    }
    .heading .col2 {
        display: flex;
        flex-direction: row;
        color: $color-alt5;
    }
    .heading .col2 p {
        margin-right: 20px;
        font-weight: 500;
        &:last-child {
            margin-right: 0;
        }
    }
    .heading .col2 span {
        font-weight: 100;
    }
    #short_description_content p {
        max-width: unset;
        margin-top: -8px;
        color: #000;
    }
    .primary_block.row {
        padding: 0 15px;
    }
    h1 {
        font-family: $font1;
        color: #000;
        font-weight: 600;
        margin-top: 0;
        font-size: 40px;
        margin-bottom: 0;
        padding: 0;
        text-transform: uppercase;
    }
    h3 {
        font-size: 22px;
        font-weight: bold;
    }
    .productParametersWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .productParametersWrap .item {
        width: 50%;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px solid $color-alt5;
        border-top: 1px solid $color-alt5;
        border-collapse: collapse;
        margin-bottom: -1px;
        padding: 20px 0;
    }
    .productParametersWrap .item span {
        font-weight: 100;
    }
    .pb-right-column p {
        margin: 0;
    }
    .quantity-box #quantity_wanted_p {
        display: flex;
        flex-direction: row;
    }
    .plusminusWrap {
        display: flex;
        flex-direction: column;
    }
    .quantity-box .option {
        display: block;
        float: unset;
        margin-bottom: 0;
        padding: 0;
        border: 1px solid $color-alt5;
        border-radius: 10px;
        height: 60px;
        margin-right: 10px;
    }
    .quantity-box #add_to_cart {
        float: right;
        border-radius: 0;
        padding: 5px 50px !important;
        width: auto;
        height: 60px;
        border-radius: 10px;
        color: #fff !important;
        background-color: $color-base !important;
        text-transform: uppercase;
        &:hover, &:focus {
            color: $color-base !important;
            background-color: #fff !important;
        }
    }
    span#availability_value {
        font-size: 14px;
        color: $color-base6;
    }
    .btn-effect:hover:after {
        display: none;
    }
    .content_prices {
        display: block;
        margin-top: 0;
    }
    .content_prices p.text {
        font-size: 20px;
    }
    #old_price {
        color: $color-alt5;
        font-size: 20px;
        margin-right: 10px;
    }
    .quantity-box {
        display: flex;
        margin: 0;
        flex-direction: row;
        align-items: center;
    }
    .productInfo {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $color-alt5;
        padding-bottom: 20px;
    }
    .productInfo .col1,
    .productInfo .col2 {
        width: 100%;
    }
    .pb-left-column #image-block {
        width: 100%;
        text-align: center;
        padding-left: 0;
    }
    .pb-left-column {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }
    .pb-right-column {
        width: 50%;
        margin-left: 40px;
        padding: 0;
    }
    div#more_info_block {
        display: flex;
        flex-direction: row;
        display: none;
    }
    div#more_info_block .col1,
    div#more_info_block .col2 {
        width: 50%;
        margin: 0 20px;
    }
    div#more_info_block .col2 {
        margin-right: 0;
    }
    div#more_info_block .col1 {
        margin: 0 10px;
        padding-right: 40px;
    }
    span#view_full_size {
        display: inline-block;
        width: 100%;
        max-height: 500px;
        overflow: hidden;
    }
    .container-product {
        display: flex;
        flex-direction: row;
    }
    table.productFeatures td,
    table.productFeatures th {
        border: 1px solid rgb(206, 206, 206);
        font-size: 1.4em
    }
    table.productFeatures th {
        width: 200px;
    }
    table.productFeatures {
        width: 100%;
        max-width: 700px;
    }
    fieldset.attribute_fieldset {
        padding: 0;
        margin-bottom: 20px !important;
    }
    label.attribute_label {
        cursor: auto;
    }
    span.text-label {
        color: rgb(17, 17, 17);
        font-size: 15px;
        margin: 0;
        float: left;
        margin-right: 10px;
        line-height: 59px;
        border-left: 1px solid $color-alt5;
        padding-left: 10px;
    }
    .quantity-control .input-group-addon, .input-group-btn {
        width: auto;
        cursor: pointer;
        border: none;
        border-radius: 0;
        background-color: rgb(218, 218, 218);
        padding: 5px;
        height: 29px;
        line-height: 20px;
    }
    input#quantity_wanted {
        width: 50px;
        text-align: center;
        display: block;
        border: none;
        padding: 0 !important;
        height: auto;
        background-color: rgba(0, 0, 0, 0);
        line-height: 59px;
        border-right: 1px solid $color-alt5;
        font-size: 19px;
    }
    #attributes {
        display: block;
    }
    #attributes .input-group {
        display: flex;
        align-items: center;
    }
    #attributes div.selector {
        min-width: auto !important;
        padding: 0 20px 0 0;
    }
    #attributes .attribute_list.product-options .attribute_arrow {
        border: 1px solid $color-base;
        padding: 0 10px;
        &:hover, &:focus {
            border: 1px solid $color-base2;
        }
    }
    form#buy_block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .pb-right-column .availability_statut {
        margin: 20px 0;
    }
    .pb-right-column .availability_statut span.label-warning {
        font-size: 18px;
    }
}
#off-canvas-menu ul.nav li ul a {
    text-transform: none;
}
#off-canvas-menu ul.nav > li > a > em {
    color: #fff;
    background-color: #ef4036;
    border-radius: 50%;
}
.sticker {
    position: absolute;
    top: -25px;
    left: -25px;
    background-color: #ef0d00;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 16px;
    color: #fff;
    width: 50px;
    height: 50px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    font-weight: 900;
    box-shadow: 0 0 0 rgba(#ef0d00, .4);
    -webkit-animation: pulse 1.5s infinite;
    animation: pulse 1.5s infinite;
    .product-box & {
        top: 15px;
        left: unset;
        right: 15px;
        font-size: 13px;
        width: 40px;
        height: 40px;
    }
}
@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(#ef0d00, .4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(#ef0d00, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(#ef0d00, 0);
    }
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(#ef0d00, .4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(#ef0d00, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(#ef0d00, 0);
    }
}