// main: master.scss
/*4 smartphones - landscape */ @media (max-width: 767px) { /*-------------------------------------- smartphones - landscape*/
.table-responsive {
    overflow-x: hidden;
}
#cart_summary {
    thead {
        display: none;
    }
    tfoot tr,
    tbody td {
        display: block;
    }
    tbody td {
        border-bottom: none !important;
        border-left: none !important;
    }
    tbody tr {
        border-top: 2px solid rgb(0, 69, 126);
        display: block;
        position: relative;
    }
    tbody td .quantity-box {
        max-width: 150px;
        margin-left: auto;
    }
    tbody td.cart_description {
        text-align: center;
    }
    tbody td.cart_avail {
        text-align: right;
    }
    tbody td.cart_delete {
        position: absolute;
        right: 0;
        top: 0;
    }
    tbody td.cart_delete div {
        margin-top: 0;
    }
    tbody td {
        &::before {
            position: absolute;
            left: 10px;
            line-height: 26px;
            font-weight: 500;
        }
        &.cart_avail::before {
            content: "Dostupnosť:";
        }
        &.cart_unit::before {
            content: "Jednotková cena:";
        }
        &.cart_quantity::before {
            content: "Množstvo:";
        }
        &.cart_total::before {
            content: "Celkom:";
        }
    }
    tfoot {
        border-top: 3px solid rgb(0, 69, 126);
    }
    #cart_voucher {
        border-top: 3px solid rgb(0, 69, 126);
    }
    tfoot tr {
        display: flex;
        flex-direction: row;
        td {
            width: 50%;
            border: none !important;
        }
    }
    tfoot td.thick-border {
        border-bottom: none !important;
    }
    #send-code,
    #discount_name {
        width: 100%;
    }
}
#categories_block_left li {
    position: relative;
    display: inline-block;
    padding-right: 30px;
}
#category {
    .content-aside.left #categories_block_left ul li a,
    .content-aside.left #layered_block_left .layered_filter_ul li:last-child a,
    .content-aside.left #categories_block_left .block_content>ul>li>a {
        font-size: 15px;
        padding-right: 0;
    }
    .row.master {
        display: flex;
        flex-direction: column-reverse;
        margin: 0;
    }
    section#center_column {
        width: 100%;
        float: unset;
    }
    aside.content-aside.left {
        border-right: none;
        border-top: 1px solid $color-alt5;
        width: 100%;
        float: unset;
    }
    .filterblock .btn {
        width: 100%;
    }
}
.CTAsection {
    .wrap {
        display: block;
        text-align: center;
    }
    p {
        margin-bottom: 30px;
    }
}
section.page-content,
section.site-content {
    .addon-title {
        text-align: center;
    }
}
#address,
#authentication,
#order-opc {
    form#new_account_form {
        width: 100%;
    }
    div#HOOK_PAYMENT {
        display: block;
        text-align: center;
    }
    #HOOK_PAYMENT .col-xs-12 {
        float: unset;
    }
    #HOOK_PAYMENT .col {
        margin: 10px 0;
    }
}
#cart_summary {
    margin: 0;
}
#product {
    .content_prices p.text {
        text-align: right;
    }
    .content_prices {
        text-align: right;
    }
    .quantity-box {
        justify-content: flex-end;
    }
    section.page-product-box.blockproductscategory.accessories-section {
        margin: 0 20px 100px;
    }
    section.page-product-box.blockproductscategory.accessories-section .addon-title {
        margin-top: 0;
    }
    h1 {
        font-size: 25px;
    }
    .heading .col2 {
        display: block;
    }
    .heading .col2 p {
        width: auto;
        margin-right: 0;
        text-align: right;
    }
    .container-product {
        flex-direction: column;
    }
    .pb-left-column {
        width: 100%;
        text-align: center;
    }
    .pb-right-column {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
    div#more_info_block {
        display: block;
    }
    div#more_info_block .col1,
    div#more_info_block .col2 {
        width: 100%;
        margin: 0;
        padding: 0 15px;
    }
    #attributes {
        flex-direction: column;
        align-items: flex-start;
    }
    fieldset.attribute_fieldset {
        margin-bottom: 20px !important;
    }
    #short_description_content p {
        text-align: left !important;
    }
}
.product_list .item.ajax_block_product {
    width: 70%;
    float: unset;
    margin: auto;
}
a.product-btn.cart-button.ajax_add_to_cart_button {
    display: block;
}
.section-slider {
    .slider {
        background: transparent !important;
    }
    .jms-slider-wrapper .slide {
        background-position: left !important;
        background-size: 180% 300px !important;
    }
    .fraction-slider .fs_obj.text1 {
        left: 20px;
        top: 30px !important;
        font-size: 21px !important;
        color: #fff !important;
    }
    .fraction-slider .fs_obj.text2 {
        left: 20px;
        top: 80px !important;
        font-size: 45px !important;
        line-height: 45px !important;
    }
    .fraction-slider .fs_obj.btn {
        left: unset;
        top: unset !important;
        bottom: 50px;
        right: 50px;
        padding: 0 40px 0 35px !important;
    }
    .fs-pager-wrapper {
        bottom: 10px;
        right: 10px;
    }
}
.brands {
    img {
        height: auto;
    }
    p {
        text-align: center;
    }
}
.info {
    table td, table th {
        text-align: center;
    }
    .layout-column {
        padding: 0;
    }
    .row {
        padding: 30px 10px;
    }
    tr {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .layout-column.col-lg-4 {
        width: count(3);
    }
}
.info2 {
    .row {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
    }
    .layout-column {
        padding: 10px 20px !important;
        width: 50% !important;
    }
}
section.page-content, section.site-content {
    margin-top: 134px;
}
header.sticky {
    .layout-column.menu {
        height: 64px;
    }
    #open-button {
        margin: 2px 0;
    }
}
header {
    div#jms_ajax_search {
        display: none;
    }
    .layout-column.menu {
        padding-left: 0;
    }
    .header-top .menu .addon-box:nth-child(2) {
        width: auto;
    }
    .header-top .right .user-info ul li a {
        width: 70px;
        margin-right: 20px;
        line-height: 0;
    }
    .header-top .container {
        padding: 0;
    }
    .header-toprow {
        display: none;
    }
    .header-toprow .addon-box {
        flex-direction: column;
        text-align: center;
    }
    .header-toprow .layout-column {
        width: 100%;
    }
    .header-toprow p {
        padding: 0 !important;
    }
}
footer {
    .footer-navbar .row {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .footer-navbar .layout-column:nth-child(2) .addon-box {
        padding-left: 0;
    }
}
#index .category .row {
    flex-wrap: wrap;
}
#index .category .layout-column {
    width: 50%;
}
#index .category .layout-column:nth-child(1),
#index .category .layout-column:nth-child(2) {
    margin-bottom: 50px;
}
.row {
    margin: 0 20px;
}
header .header-top .right {
    right: 62px;
}
.menu-button .fa {
    color: rgb(255, 255, 255);
    line-height: 29px;
}
#category .filterblock .layered_filter_ul {
    display: none;
}
.fancybox-skin {
    padding: 0 !important;
}
.fancybox-wrap {
    width: 100% !important;
    left: 0 !important;
}
.fancybox-inner {
    width: 100% !important;
    height: auto !important;
}
.fancybox-close {
    right: 0;
}
}/* end.smartphones - landscape*/
